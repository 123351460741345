import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { RenderModeServiceV1, RenderMode } from '@oneaudi/render-mode-service';
import { debug } from './utils/debugging';

interface ContextState {
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly isSSR: boolean;
  readonly renderMode: RenderMode;
  readonly isDefaultRenderMode: boolean;
  readonly isEditRenderMode: boolean;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
  readonly asyncSsrManager?: AsyncSsrManagerV1;
  readonly renderModeService?: RenderModeServiceV1;
}

const ContextProvider = ({
  children,
  loggerService: logger,
  trackingService,
  asyncSsrManager,
  renderModeService,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(() => {
    const renderMode =
      renderModeService && renderModeService.getRenderMode() === RenderMode.EDIT
        ? RenderMode.EDIT
        : RenderMode.DEFAULT;

    debug(
      `renderMode == ${renderMode} == ${renderMode === RenderMode.DEFAULT ? 'DEFAULT' : 'EDIT'}`,
    );

    return {
      loggerService: logger,
      trackingService,
      isSSR: typeof asyncSsrManager !== 'undefined',
      renderMode,
      isDefaultRenderMode: renderMode === RenderMode.DEFAULT,
      isEditRenderMode: renderMode === RenderMode.EDIT,
    };
  }, [trackingService, renderModeService]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export default ContextProvider;
