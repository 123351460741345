import { ContentFragment } from '@oneaudi/falcon-tools';

/* eslint-disable @typescript-eslint/no-namespace */
export type Environment = 'development' | 'production';

export type AlignTypes = 'left' | 'center';
export type QuoteSizes = 'H1 (SEO H2)' | 'H2 (SEO H3)';
export type QuotationMarkTypes = 'de' | 'fr' | 'ites' | 'en';
export type Theme = 'Dark' | 'Light';

// valid colors, see color tokens on
// https://react.ui.audi/?path=/story/brand-identity-design-tokens--page#colors
export type TextColors = 'Black' | 'White' | undefined;
export type BackgroundColors = 'Black' | 'White' | 'Red' | undefined;

export const FA_LOCATOR = `[data-test-id=quote]`;

export interface EditorJsonContent {
  __type?: 'editor.json'; // This will in reality never be set, but it makes mapping easier

  quoteTextOption: {
    quoteText: string;
  };
  quoteSourceOption: {
    quoteSource?: string;
  };
  quotationMarkOption: {
    quotationMark?: QuotationMarkTypes;
  };
  sizeOption: {
    size?: QuoteSizes;
  };
  alignOption: {
    align?: AlignTypes;
  };
  themeOption: {
    theme?: Theme;
  };
  colorOption: {
    color?: TextColors;
    bgColor?: BackgroundColors;
  };
}

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    quoteTextOption_quoteText: string;
    quoteSourceOption_quoteSource: string;
    quotationMarkOption_quotationMark: QuotationMarkTypes;
    sizeOption_size: QuoteSizes;
    alignOption_align: AlignTypes;
    colorOption_color: TextColors;
    colorOption_bgColor: BackgroundColors;
    themeOption_theme: Theme;
  };
}

export interface AppContent {
  quoteText: string;
  quoteSource?: string;
  quotationMark?: QuotationMarkTypes;
  size: QuoteSizes;
  align: AlignTypes;
  theme: Theme; // new theme property
  color?: TextColors;
  bgColor?: BackgroundColors;
}
