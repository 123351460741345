/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { Quote } from './components/quote/Quote';
import { AppContent } from '../types';
import { mapContent } from './utils/contentMapping';

interface FeatureAppProps {
  contentService: ContentServiceV1;
}

const FeatureApp: React.FC<FeatureAppProps> = ({ contentService }: FeatureAppProps) => {
  const [content, setContent] = React.useState<AppContent | undefined>(mapContent(contentService));

  React.useEffect(() => {
    const listener = () => {
      setContent(mapContent(contentService));
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  return content && <Quote {...content} />;
};

export default FeatureApp;
