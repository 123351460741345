import { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, EditorJsonContent, FalconContent } from '../../types';
import { debug } from './debugging';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as EditorJsonContent | FalconContent;

  if (content.__type === 'aem-headless') {
    // Perform runtime validation
    if (!isFalconContent(content)) {
      // Throw `InvalidContentError` here if provided content
      // is not valid. This will display as a special error message
      // in the frontend on author environments.
      throw new InvalidContentError('Content is invalid.');
    }

    // map CF content to internal structure
    return mapFalconContent(content);
  }

  // map FACE content to internal structure
  return mapEditorJsonContent(content);
}

/**
 * Map EditorJsonContent to AppContent
 *
 * @param content EditorJsonContent
 * @returns AppContent
 */
export function mapEditorJsonContent(content: EditorJsonContent): AppContent {
  debug('mapEditorJsonContent', content);

  return {
    quoteText: content.quoteTextOption.quoteText,
    quoteSource: content.quoteSourceOption.quoteSource,
    quotationMark: content.quotationMarkOption.quotationMark || 'en',
    size: content.sizeOption.size || 'H1 (SEO H2)',
    align: content.alignOption.align || 'left',
    theme: content.themeOption.theme || 'Light',
    color: content.colorOption.color,
    bgColor: content.colorOption.bgColor,
  };
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent): AppContent {
  debug('mapFalconContent', content);

  return {
    quoteText: content.fields.quoteTextOption_quoteText || '',
    quoteSource: content.fields.quoteSourceOption_quoteSource,
    quotationMark: content.fields.quotationMarkOption_quotationMark || 'en',
    size: content.fields.sizeOption_size || 'H1 (SEO H2)',
    align: content.fields.alignOption_align || 'left',
    theme: content.fields.themeOption_theme || 'Light',
    color: content.fields.colorOption_color,
    bgColor: content.fields.colorOption_bgColor,
  };
}
