/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import {
  ThemeProvider,
  SYS_BREAKPOINT_XL,
  SYS_BREAKPOINT_2_XL,
  SYS_SPACE_FIXED_300,
  SYS_SPACE_FIXED_900,
  SYS_SPACE_FIXED_2600,
  GLOBAL_COLOR_CANVAS_DEFAULT_DARK,
  GLOBAL_COLOR_CANVAS_DEFAULT_LIGHT,
  SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT,
  SYS_COLOR_FOREGROUND_NEUTRAL_100_DARK,
  SYS_COLOR_SURFACE_HIGHLIGHT_PRIMARY_LIGHT,
  SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_DARK,
  SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_LIGHT,
} from '@oneaudi/unified-web-common';

import { Text, Headline } from '@oneaudi/unified-web-components';

import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import {
  AppContent,
  AlignTypes,
  QuotationMarkTypes,
  BackgroundColors,
  TextColors,
} from '../../../types';
import { enableSoftHyphen } from '../../utils/textUtils';
import { useTracking } from '../../utils/useTracking';

type HeadingTagNames = 'h1' | 'h2';

interface ContainerProps {
  align: AlignTypes;
  backgroundColor: BackgroundColors;
  whiteText: boolean;
}

const Container = styled.div<ContainerProps>`
  padding-top: var(--spacing-relative-3xl);
  padding-bottom: var(--spacing-relative-3xl);

  padding-left: var(--page-margin);
  padding-right: var(--page-margin);

  display: flex;
  flex-direction: row;

  ${(props) => {
    switch (props.align) {
      case 'center':
        return css`
          text-align: center;
          justify-content: center;
        `;
      default:
        return css`
          text-align: left;
        `;
    }
  }}

  background-color: ${({ backgroundColor, whiteText, theme }) =>
    getCanvasColor(theme, backgroundColor, whiteText)};
`;

const QuoteText = styled.span<{ color: TextColors }>`
  color: ${(props) => getTextColor(props.theme, props.color)};

  sup {
    // footnotes too small fix
    font-weight: bold;
    vertical-align: super;
    font-size: 16px !important;
  }

  a {
    &:hover {
      color: ${(props) => adjustDisclaimerColor(props.color)};
    }
  }
`;

const QuoteMargin = styled.div<{ spaceInlineEnd?: boolean }>`
  padding-inline-end: ${(props) => (props.spaceInlineEnd ? '8px' : '0')};
  text-align: right;

  width: ${SYS_SPACE_FIXED_300};

  @media (min-width: ${SYS_BREAKPOINT_XL}) {
    padding-left: ${SYS_SPACE_FIXED_900};
  }

  @media (min-width: ${SYS_BREAKPOINT_2_XL}) {
    width: ${SYS_SPACE_FIXED_2600};
  }
`;

const ClosingQuoteSign = styled.span`
  padding-left: 2px;
`;

const OriginatorText = styled(Text)<{ color: TextColors; bgColor: BackgroundColors | undefined }>`
  margin-top: var(--spacing-relative-xl);

  color: ${(props) => {
    if (props.color === 'White' || props.color === 'Black') {
      return getTextColor(props.theme, props.color);
    }
    if (props.bgColor === 'Red' && props.color === 'Auto') {
      return getTextColor(props.theme, undefined);
    }
    return props.theme.sys.color.foreground.neutral[200];
  }};

  sup {
    // footnotes too small fix
    vertical-align: super;
    font-size: 9px !important;
  }
`;

export const Quote: React.FC<AppContent> = (props) => {
  const { align, bgColor, color, size, theme, quoteText, quotationMark, quoteSource } = props;

  const ref = React.useRef(null);

  const tracking = useTracking(props, ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  const headingTag = (size === 'H2 (SEO H3)' ? `h3` : 'h2') as HeadingTagNames;
  const headingVariantOrder = size === 'H2 (SEO H3)' ? `2` : '1';

  const transformedQuoteText = renderTextWithFootnotesReferencesV2(enableSoftHyphen(quoteText));
  const transformedQuoteSource = quoteSource
    ? renderTextWithFootnotesReferencesV2(quoteSource)
    : undefined;

  return (
    <ThemeProvider theme={{ name: theme === 'Dark' ? 'dark' : 'light' }}>
      <Container
        ref={ref}
        data-test-id="quote"
        backgroundColor={bgColor}
        whiteText={color === 'White'}
        align={align}
      >
        <QuoteMargin spaceInlineEnd>
          {align === 'left' && (
            <Headline
              tag={headingTag}
              variant={{ order: headingVariantOrder, type: 'headline', weight: 'normal' }}
            >
              <QuoteText color={color}>{getStartQuoteSign(quotationMark)}</QuoteText>
            </Headline>
          )}
        </QuoteMargin>
        <div>
          <Headline
            tag={headingTag}
            variant={{ order: headingVariantOrder, type: 'headline', weight: 'normal' }}
          >
            <QuoteText color={color}>
              {align === 'center' && getStartQuoteSign(quotationMark)}
              <UeElement type="text" property="quoteTextOption_quoteText" label="Quote Text">
                {transformedQuoteText}
              </UeElement>
              <ClosingQuoteSign>{getEndQuoteSign(quotationMark)}</ClosingQuoteSign>
            </QuoteText>
          </Headline>
          {transformedQuoteSource && (
            <OriginatorText
              color={color}
              bgColor={bgColor}
              variant={{ order: '1', style: 'normal', type: 'copy' }}
            >
              <UeElement type="text" property="quoteSourceOption_quoteSource" label="Quote Source">
                {transformedQuoteSource}
              </UeElement>
            </OriginatorText>
          )}
        </div>
        <QuoteMargin />
      </Container>
    </ThemeProvider>
  );
};

function getTextColor(theme: DefaultTheme, color: TextColors) {
  if (color === 'White') {
    return SYS_COLOR_FOREGROUND_NEUTRAL_100_DARK;
  }

  if (color === 'Black') {
    return SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT;
  }

  return `${theme.sys.color.foreground.neutral[100]}`;
}

function getCanvasColor(theme: DefaultTheme, color: BackgroundColors, whiteText: boolean) {
  if (color === 'White') {
    return GLOBAL_COLOR_CANVAS_DEFAULT_LIGHT;
  }

  if (color === 'Black') {
    return GLOBAL_COLOR_CANVAS_DEFAULT_DARK;
  }

  if (color === 'Red') {
    if (theme.name === 'dark' && whiteText) {
      // use Red color from light theme for accessiblity
      return SYS_COLOR_SURFACE_HIGHLIGHT_PRIMARY_LIGHT;
    }
    return `${theme.sys.color.surface.highlight.primary}`;
  }

  return `${theme.global.color.canvas.default}`;
}

function adjustDisclaimerColor(color: TextColors): string {
  if (color === 'White') {
    return `${SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_DARK} !important`;
  }

  if (color === 'Black') {
    return `${SEM_COLOR_ACTION_SECONDARY_FOREGROUND_TEXT_HOVER_LIGHT} !important`;
  }
  return 'auto';
}

function getStartQuoteSign(quotationMark?: QuotationMarkTypes) {
  switch (quotationMark) {
    case 'de': // German
      return '\u201E';
    case 'fr': // French
      return '\u00AB';
    case 'ites': // Italian/Spanish
      return '\u0022';
    default:
      return '\u201C'; // English
  }
}

function getEndQuoteSign(quotationMark?: QuotationMarkTypes) {
  switch (quotationMark) {
    case 'de': // German
      return '\u201C';
    case 'fr': // French
      return '\u00BB';
    case 'ites': // Italian/Spanish
      return '\u0022';
    default:
      return '\u201D'; // English
  }
}
